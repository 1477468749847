<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    객실등록/수정
                                </v-col>
                                <v-col cols="12" class="" md="12">

                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">진열여부</th>
                                            <td class="td">
                                                <input type="radio" id="Y" value="Y" v-model="roomInfo.useYn"
                                                       style="width: 20px; height: 12px;">
                                                <label for="Y">진열</label>
                                                <input type="radio" id="N" value="N" v-model="roomInfo.useYn"
                                                       style="width: 20px; height: 12px;">
                                                <label for="N">진열안함</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">관리여부</th>
                                            <td class="td">
                                                <input type="radio" id="delY" value="N" v-model="roomInfo.delYn"
                                                       style="width: 20px; height: 12px;">
                                                <label for="delY">관리</label>
                                                <input type="radio" id="delN" value="Y" v-model="roomInfo.delYn"
                                                       style="width: 20px; height: 12px;">
                                                <label for="delN">관리안함</label>
                                                *관리자목록 삭제
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">객실순서</th>
                                            <td class="td">
                                                <input id="roomOrder" v-model="roomInfo.roomOrder" style="width: 20%">
                                                *숫자가 낮을수록 먼저 출력됩니다.

                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    기본정보
                                </v-col>
                                <v-col cols="12" class="" md="12">

                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th" colspan="2">객실명</th>
                                            <td class="td">
                                                <input id="roomNm" v-model="roomInfo.roomNm" style="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" colspan="2">객실구분</th>
                                            <td class="td">
                                                <!--<input id="roomGubun" v-model="roomInfo.roomGubun" style="">-->
                                                <select v-model="roomInfo.roomGubun" style="margin:3px;width: 180px;">
                                                    <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                                    <option value="R">객실</option>
                                                    <option value="A">캠핑장A</option>
                                                    <option value="B">캠핑장B</option>
                                                    <option value="C">캠핑장C</option>
                                                    <option value="D">캠핑장D</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" colspan="2">객실형태</th>
                                            <td class="td">
                                                <input id="roomForm" v-model="roomInfo.roomForm" style="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" colspan="2">평수</th>
                                            <td class="td">
                                                <input id="roomArc" v-model="roomInfo.roomArc" style="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" colspan="2">방갯수</th>
                                            <td class="td">
                                                <input id="roomNum" v-model="roomInfo.roomNum" style="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" colspan="2">기준인원</th>
                                            <td class="td">
                                                <input id="stdNop" v-model="roomInfo.stdNop" style="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" colspan="2">최대인원</th>
                                            <td class="td">
                                                <input id="maxNop" v-model="roomInfo.maxNop" style="">
                                            </td>
                                        </tr>

                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    이용요금
                                    <v-btn
                                            v-if="roomInfo.roomGubun != 'R'"
                                            class="mx-3 white--text center-block tmp-btn float-right"
                                            style="font-weight: bold; background-color: #E53935;"
                                            small
                                            @click="openRoomPrice"
                                    >
                                        일괄변경
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <table class="table-top-s table-top-s-b input-align-right">
                                        <colgroup>
                                            <col style="width:10%"/>
                                            <col style="width:30%"/>
                                            <col style="width:30%"/>
                                            <col style="width:30%"/>
                                        </colgroup>
                                        <tr>
                                            <th>구분</th>
                                            <th>비수기</th>
                                            <th>준성수기</th>
                                            <th>성수기</th>
                                        </tr>
                                        <tr>
                                            <th>주중</th>
                                            <td class="td">
                                                <input id="osrWe" v-model="roomInfo.osrWe" style=""
                                                       @keyup="inputNumberFormat('osrWe')">
                                            </td>
                                            <td class="td">
                                                <input id="spsrWe" v-model="roomInfo.spsrWe" style=""
                                                       @keyup="inputNumberFormat('spsrWe')">
                                            </td>
                                            <td class="td">
                                                <input id="psrWe" v-model="roomInfo.psrWe" style=""
                                                       @keyup="inputNumberFormat('psrWe')">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>주말</th>
                                            <td class="td">
                                                <input id="osrWd" v-model="roomInfo.osrWd" style=""
                                                       @keyup="inputNumberFormat('osrWd')">
                                            </td>
                                            <td class="td">
                                                <input id="spsrWd" v-model="roomInfo.spsrWd" style=""
                                                       @keyup="inputNumberFormat('spsrWd')">
                                            </td>
                                            <td class="td">
                                                <input id="psrWd" v-model="roomInfo.psrWd" style=""
                                                       @keyup="inputNumberFormat('psrWd')">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>1인추가</th>
                                            <td class="td">
                                                <input id="osrScg" v-model="roomInfo.osrScg" style=""
                                                       @keyup="inputNumberFormat('osrScg')">
                                            </td>
                                            <td class="td">
                                                <input id="spsrScg" v-model="roomInfo.spsrScg" style=""
                                                       @keyup="inputNumberFormat('spsrScg')">
                                            </td>
                                            <td class="td">
                                                <input id="psrScg" v-model="roomInfo.psrScg" style=""
                                                       @keyup="inputNumberFormat('psrScg')">
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>

                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    객실시설
                                </v-col>
                                <v-col>
                                    <table class="table-top-s table-top-s-b">
                                        <tr>
                                            <td>
                                                <template v-for="item in roomItemList">
                                                    <div class="test_checkbox">
                                                        <input type="checkbox" :id="item.roomItemId"
                                                               v-model="item.useYn"
                                                               class="checkbox-custom-admin-m0">
                                                        <label class="label-custom-admin">{{item.roomItemName}}</label>
                                                    </div>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>

                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    사진등록
                                    <span style="font-weight: normal; font-size:0.9rem;">
                                        (추가등록시 파일을 선택해주세요.)
                                    </span>
                                </v-col>
                                <v-col cols="12">
                                    <input type="file" name="files" accept="image/*" @change="uploadImage"/>

                                    <!--<v-btn
                                            color="primary"
                                            class="mx-4 white&#45;&#45;text center-block tmp-btn"
                                            x-small
                                            @click="addImg()"
                                    >
                                        추가
                                    </v-btn>-->
                                    <v-row class="my-3">
                                        <v-col
                                                cols="6" md="4"
                                                v-for="(item,i) in roomImgList"
                                                :key="i"
                                        >
                                            <v-card>
                                                <v-img
                                                        :src="item.roomImgRoute"
                                                        :lazy-src="item.roomImgRoute"
                                                        aspect-ratio="1.5"
                                                        class="grey lighten-2"
                                                ></v-img>
                                                <v-card-title class="text-h6">

                                                </v-card-title>
                                                <v-card-subtitle>
                                                    <div>순서 :
                                                        <input id="roomImgOrder" v-model="item.roomImgOrder"
                                                               style="border: 1px solid silver;width: 30%;padding:0 3px;">
                                                        <v-btn
                                                                color="error"
                                                                class="mx-0 white--text center-block float-right"
                                                                x-small
                                                                @click="rowClick(item)"
                                                        >
                                                            삭제
                                                        </v-btn>
                                                    </div>
                                                </v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="my-1" md="12">
                                    <v-col cols="12" style="text-align: center">
                                        <v-btn
                                                color="primary"
                                                class="mx-4 white--text center-block"
                                                @click="btnClick(true)"
                                        >
                                            저장
                                        </v-btn>
                                        <v-btn
                                                class="mx-4 center-block"
                                                @click="btnClick(false)"
                                        >
                                            목록
                                        </v-btn>
                                    </v-col>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

        <!-- 다이얼로그 -->
        <v-row justify="center">
            <v-dialog
                    v-model="dialog"
                    max-width="450px"
            >

                <v-card>
                    <v-card-text class="px-1">
                        <v-row>
                            <v-col cols="12" class="">
                                <v-col cols="12" class="reservation-select-title mt-6">
                                    일괄변경 - 캠핑장 {{roomInfo.roomGubun}}
                                </v-col>
                                <v-col cols="12">
                                    <table class="table-top-s table-top-s-b input-align-right">
                                        <colgroup>
                                            <col style="width:20%"/>
                                            <col style="width:26%"/>
                                            <col style="width:26%"/>
                                            <col style="width:26%"/>
                                        </colgroup>
                                        <tr>
                                            <th>구분</th>
                                            <th>비수기</th>
                                            <th>준성수기</th>
                                            <th>성수기</th>
                                        </tr>
                                        <tr>
                                            <th>주중</th>
                                            <td class="td">
                                                <input v-model="allInfo.osrWe" style="" @keyup="inputNumberFormat('osrWe1')">
                                            </td>
                                            <td class="td">
                                                <input v-model="allInfo.spsrWe" style="" @keyup="inputNumberFormat('spsrWe1')">
                                            </td>
                                            <td class="td">
                                                <input v-model="allInfo.psrWe" style="" @keyup="inputNumberFormat('psrWe1')">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>주말</th>
                                            <td class="td">
                                                <input v-model="allInfo.osrWd" style="" @keyup="inputNumberFormat('osrWd1')">
                                            </td>
                                            <td class="td">
                                                <input v-model="allInfo.spsrWd" style="" @keyup="inputNumberFormat('spsrWd1')">
                                            </td>
                                            <td class="td">
                                                <input v-model="allInfo.psrWd" style="" @keyup="inputNumberFormat('psrWd1')">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>1인추가</th>
                                            <td class="td">
                                                <input v-model="allInfo.osrScg" style="" @keyup="inputNumberFormat('osrScg1')">
                                            </td>
                                            <td class="td">
                                                <input v-model="allInfo.spsrScg" style="" @keyup="inputNumberFormat('spsrScg1')">
                                            </td>
                                            <td class="td">
                                                <input v-model="allInfo.psrScg" style="" @keyup="inputNumberFormat('psrScg1')">
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-col>
                        </v-row>
                        <!--<small>*indicates required field</small>-->
                        <v-row>
                            <div style="margin: 0 auto;">
                                <v-btn
                                        color="primary"
                                        @click="setRoomPrice()"
                                >
                                    저장
                                </v-btn>
                                <v-btn
                                        style="margin:20px;"
                                        color=""
                                        @click="dialog = false"
                                >
                                    취소
                                </v-btn>
                            </div>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <!--<v-spacer></v-spacer>-->

                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminRoomInfo',
        components: {
            AdminRoomMenu
        },
        data: () => ({
            dialog: false,
            roomId: "",
            roomId_img: "",
            roomList: [],
            roomInfo: {},
            roomImgList: [],
            roomImgInfo: {},
            roomItemList: [],
            roomImgRoute_tmp: "",
            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
                menu5: 0,
            },
            allInfo: {}, // 일괄변경용

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            if (this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }

            this.roomId = this.$getParameterByName('roomId');

            if (this.roomId === "") {
                //alert("객실 ID가 없습니다. 다시 시도해주세요.");

                //window.history.back();
                this.newRoomInfo();
            } else {
                this.getRoomInfo();
            }
        },
        methods: {
            btnClick(val) {

                if (val) {
                    this.saveRoomInfo();
                } else {
                    this.$router.push({path: "/admin/room/list"});
                }

            },
            openRoomPrice() {

                let formdata = {
                    roomGubun: this.roomInfo.roomGubun,
                    roomId: this.roomInfo.roomGubun + "00",
                }

                return this.$store.dispatch("admin/getCampInfo", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.allInfo = resp.message;

                            // 이용요금 컴마 붙이기
                            if (this.allInfo.osrScg !== undefined) {
                                this.allInfo.osrScg = this.comma(this.allInfo.osrScg);
                            }
                            if (this.allInfo.osrWd !== undefined) {
                                this.allInfo.osrWd = this.comma(this.allInfo.osrWd);
                            }
                            if (this.allInfo.osrWe !== undefined) {
                                this.allInfo.osrWe = this.comma(this.allInfo.osrWe);
                            }

                            if (this.allInfo.psrScg !== undefined) {
                                this.allInfo.psrScg = this.comma(this.allInfo.psrScg);
                            }
                            if (this.allInfo.psrWd !== undefined) {
                                this.allInfo.psrWd = this.comma(this.allInfo.psrWd);
                            }
                            if (this.allInfo.psrWe !== undefined) {
                                this.allInfo.psrWe = this.comma(this.allInfo.psrWe);
                            }

                            if (this.allInfo.spsrScg !== undefined) {
                                this.allInfo.spsrScg = this.comma(this.allInfo.spsrScg);
                            }
                            if (this.allInfo.spsrWd !== undefined) {
                                this.allInfo.spsrWd = this.comma(this.allInfo.spsrWd);
                            }
                            if (this.allInfo.spsrWe !== undefined) {
                                this.allInfo.spsrWe = this.comma(this.allInfo.spsrWe);
                            }

                            this.dialog = true;


                        }, 300)
                    })
                    .catch((err) => {
                    })


            },
            setRoomPrice() {

                // 이용요금 컴마 제거
                if (this.allInfo.osrScg !== undefined) {
                    this.allInfo.osrScg = this.uncomma(this.allInfo.osrScg);
                }
                if (this.allInfo.osrWd !== undefined) {
                    this.allInfo.osrWd = this.uncomma(this.allInfo.osrWd);
                }
                if (this.allInfo.osrWe !== undefined) {
                    this.allInfo.osrWe = this.uncomma(this.allInfo.osrWe);
                }

                if (this.allInfo.psrScg !== undefined) {
                    this.allInfo.psrScg = this.uncomma(this.allInfo.psrScg);
                }
                if (this.allInfo.psrWd !== undefined) {
                    this.allInfo.psrWd = this.uncomma(this.allInfo.psrWd);
                }
                if (this.allInfo.psrWe !== undefined) {
                    this.allInfo.psrWe = this.uncomma(this.allInfo.psrWe);
                }

                if (this.allInfo.spsrScg !== undefined) {
                    this.allInfo.spsrScg = this.uncomma(this.allInfo.spsrScg);
                }
                if (this.allInfo.spsrWd !== undefined) {
                    this.allInfo.spsrWd = this.uncomma(this.allInfo.spsrWd);
                }
                if (this.allInfo.spsrWe !== undefined) {
                    this.allInfo.spsrWe = this.uncomma(this.allInfo.spsrWe);
                }

                return this.$store.dispatch("admin/updateAllRoom", this.allInfo)
                    .then((resp) => {
                        setTimeout(() => {

                            alert("정보를 저장 했습니다.");
                            this.dialog = false;
                            this.getRoomInfo();

                        }, 300)
                    })
                    .catch((err) => {
                        this.dialog = false;
                    })

            },

            saveRoomInfo() {
                this.roomInfo.roomImg = this.roomImgList;

                if (this.roomInfo.roomGubun === 'R') {

                } else {

                }

                this.setRoomItem();

                if (this.roomInfo.delYn === 'Y' && this.roomInfo.useYn === 'Y') {

                    alert("관리하지 안는 항목은 진열할 수 없습니다.");
                    return;
                }

                // 이용요금 컴마 붙이기
                if (this.roomInfo.osrScg !== undefined) {
                    this.roomInfo.osrScg = this.uncomma(this.roomInfo.osrScg);
                }
                if (this.roomInfo.osrWd !== undefined) {
                    this.roomInfo.osrWd = this.uncomma(this.roomInfo.osrWd);
                }
                if (this.roomInfo.osrWe !== undefined) {
                    this.roomInfo.osrWe = this.uncomma(this.roomInfo.osrWe);
                }

                if (this.roomInfo.psrScg !== undefined) {
                    this.roomInfo.psrScg = this.uncomma(this.roomInfo.psrScg);
                }
                if (this.roomInfo.psrWd !== undefined) {
                    this.roomInfo.psrWd = this.uncomma(this.roomInfo.psrWd);
                }
                if (this.roomInfo.psrWe !== undefined) {
                    this.roomInfo.psrWe = this.uncomma(this.roomInfo.psrWe);
                }

                if (this.roomInfo.spsrScg !== undefined) {
                    this.roomInfo.spsrScg = this.uncomma(this.roomInfo.spsrScg);
                }
                if (this.roomInfo.spsrWd !== undefined) {
                    this.roomInfo.spsrWd = this.uncomma(this.roomInfo.spsrWd);
                }
                if (this.roomInfo.spsrWe !== undefined) {
                    this.roomInfo.spsrWe = this.uncomma(this.roomInfo.spsrWe);
                }


                return this.$store.dispatch("admin/updateRoom", this.roomInfo)
                    .then((resp) => {
                        setTimeout(() => {
                            alert("정보를 저장 했습니다.");
                            this.roomId = resp.message.roomId;
                            location.href = "/admin/room/info" + '?roomId=' + resp.message.roomId;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

            getRoomInfo() {

                let fdata = {
                    roomId: this.roomId
                };

                return this.$store.dispatch("admin/getRoomInfo", fdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomInfo = resp.message[0];

                            // 이용요금 컴마 붙이기
                            if (this.roomInfo.osrScg !== undefined) {
                                this.roomInfo.osrScg = this.comma(this.roomInfo.osrScg);
                            }
                            if (this.roomInfo.osrWd !== undefined) {
                                this.roomInfo.osrWd = this.comma(this.roomInfo.osrWd);
                            }
                            if (this.roomInfo.osrWe !== undefined) {
                                this.roomInfo.osrWe = this.comma(this.roomInfo.osrWe);
                            }

                            if (this.roomInfo.psrScg !== undefined) {
                                this.roomInfo.psrScg = this.comma(this.roomInfo.psrScg);
                            }
                            if (this.roomInfo.psrWd !== undefined) {
                                this.roomInfo.psrWd = this.comma(this.roomInfo.psrWd);
                            }
                            if (this.roomInfo.psrWe !== undefined) {
                                this.roomInfo.psrWe = this.comma(this.roomInfo.psrWe);
                            }

                            if (this.roomInfo.spsrScg !== undefined) {
                                this.roomInfo.spsrScg = this.comma(this.roomInfo.spsrScg);
                            }
                            if (this.roomInfo.spsrWd !== undefined) {
                                this.roomInfo.spsrWd = this.comma(this.roomInfo.spsrWd);
                            }
                            if (this.roomInfo.spsrWe !== undefined) {
                                this.roomInfo.spsrWe = this.comma(this.roomInfo.spsrWe);
                            }

                            this.getRoomItem();
                            this.getRommImg();
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            newRoomInfo() {

                this.roomInfo = {
                    useYn: 'Y',
                    delYn: 'N',
                    roomGubun: 'R',

                }

                this.newRoomItem();

            },
            newRoomItem() {
                return this.$store.dispatch("admin/getRoomItemList", {})
                    .then((resp) => {
                        setTimeout(() => {
                            this.roomItemList = resp.message;
                            this.roomInfo.roomItemList = resp.message;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            getRommImg() {

                if (this.roomInfo.roomGubun === 'R') {
                    this.roomImgList = this.roomInfo.roomImg;
                    this.roomId_img = this.roomInfo.roomId;
                } else {
                    let formdata = {
                        roomId: this.roomInfo.roomGubun + "00",
                    };

                    return this.$store.dispatch("admin/getRoomImg", formdata)
                        .then((resp) => {

                            if (resp.message === undefined) {
                                //alert("잘못된 요청입니다.");
                                //window.history.back();
                                //return;
                            } else {
                                this.roomImgList = resp.message;
                                this.roomId_img = this.roomInfo.roomGubun + "00";
                            }

                        })
                        .catch((err) => {
                        })
                }

            },
            getRoomItem() {
                this.roomItemList = this.roomInfo.roomItemList;

                if (this.roomInfo.roomItem == undefined || this.roomInfo.roomItem.charAt(0) !== "[") {
                    return;
                }

                this.roomItem = JSON.parse(this.roomInfo.roomItem);

                for (let i = 0; this.roomItemList.length > i; i++) {
                    for (let j = 0; this.roomItem.length > j; j++) {

                        if (this.roomItemList[i].roomItemId == this.roomItem[j].roomItemId) {

                            this.roomItemList[i].useYn = this.roomItem[j].useYn;
                            break;
                        }
                    }
                }
            },
            setRoomItem() {
                let roomItem_temp = [];
                for (let i = 0; this.roomInfo.roomItemList.length > i; i++) {
                    let a = {}
                    if (this.roomInfo.roomItemList[i].useYn != undefined && this.roomInfo.roomItemList[i].useYn) {
                        a = {
                            roomItemId: this.roomInfo.roomItemList[i].roomItemId,
                            roomItemImg: this.roomInfo.roomItemList[i].roomItemImg,
                            useYn: this.roomInfo.roomItemList[i].useYn
                        };
                        roomItem_temp.push(a);
                    } else {
                        /*a = {
                            roomItemId : this.roomInfo.roomItemList[i].roomItemId,
                            useYn : false
                        }*/
                    }
                }
                this.roomInfo.roomItem = JSON.stringify(roomItem_temp);
            },
            uploadImage(e) {
                let url = '/file/upload.do';

                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                let header = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };

                return axios.post(url, formData, header).then(resp => {

                    if (resp.data) {

                        this.roomImgRoute_tmp = resp.data;
                        let roomImgInfo = {
                            roomId: this.roomId_img,
                            roomImgOrder: "0",
                            roomImgRoute: this.roomImgRoute_tmp,
                            roomImgId: "",
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            isAdd: true,

                        };

                        this.roomImgList.push(roomImgInfo);

                        return resp.data
                    }

                    throw new Error('Server or network error');
                });
            },
            addImg() {
                if (this.roomImgRoute_tmp.length > 0) {

                    let roomImgInfo = {
                        roomId: this.roomId_img,
                        roomImgOrder: "0",
                        roomImgRoute: this.roomImgRoute_tmp,
                        roomImgId: "",
                        lstModPrs: this.$store.state.admin.loginInfo.usrId,
                        isAdd: true,

                    };

                    this.roomImgList.push(roomImgInfo);

                } else {
                    alert("사진을 업로드 해주세요.");
                }

            },
            rowClick(item) {

                let _this = this;

                if (!confirm("사진을 삭제 하시겠습니까?")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {
                    // 확인(예) 버튼 클릭 시 이벤트
                    _this.roomImgList.forEach(function (item1, index, arr2) {
                        if (item1.roomImgRoute !== undefined && item.roomImgRoute !== undefined) {
                            if (item1.roomImgRoute === item.roomImgRoute) {
                                _this.roomImgList.splice(index, 1);
                            }
                        }
                    });
                }

            },
            comma(str) {
                if (str != undefined) {
                    str = String(str);
                    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                }

            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            inputNumberFormat(obj) {
                switch (obj) {
                    case "osrWe" : {
                        this.roomInfo.osrWe = this.roomInfo.osrWe.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "osrWd" : {
                        this.roomInfo.osrWd = this.roomInfo.osrWd.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "osrScg" : {
                        this.roomInfo.osrScg = this.roomInfo.osrScg.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "spsrWe" : {
                        this.roomInfo.spsrWe = this.roomInfo.spsrWe.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "spsrWd" : {
                        this.roomInfo.spsrWd = this.roomInfo.spsrWd.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "spsrScg" : {
                        this.roomInfo.spsrScg = this.roomInfo.spsrScg.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "psrWe" : {
                        this.roomInfo.psrWe = this.roomInfo.psrWe.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "psrWd" : {
                        this.roomInfo.psrWd = this.roomInfo.psrWd.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "psrScg" : {
                        this.roomInfo.psrScg = this.roomInfo.psrScg.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }


                    case "osrWe1" : {
                        this.allInfo.osrWe = this.allInfo.osrWe.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "osrWd1" : {
                        this.allInfo.osrWd = this.allInfo.osrWd.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "osrScg1" : {
                        this.allInfo.osrScg = this.allInfo.osrScg.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "spsrWe1" : {
                        this.allInfo.spsrWe = this.allInfo.spsrWe.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "spsrWd1" : {
                        this.allInfo.spsrWd = this.allInfo.spsrWd.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "spsrScg1" : {
                        this.allInfo.spsrScg = this.allInfo.spsrScg.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "psrWe1" : {
                        this.allInfo.psrWe = this.allInfo.psrWe.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "psrWd1" : {
                        this.allInfo.psrWd = this.allInfo.psrWd.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                    case "psrScg1" : {
                        this.allInfo.psrScg = this.allInfo.psrScg.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                        break;
                    }
                }
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {},

    }
</script>

<style>

    .test_checkbox {
        padding: 0;
        display: inline-flex;
    }

</style>

